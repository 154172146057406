@import "../../styles/color.scss";

.content {
  margin: 1rem;
  background-color: $primary-bg;
  border-radius: 8px;
}
.input-content {
  margin: 0 auto;
  padding: 1rem 0;
  width: 30%;
}

.background {
  &-dark {
    background-color: #000 !important;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
  }
  &-light {
    background-color: #f8fafc;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }
}
.star {
  font-size: 1rem;
  color: #25cff2;
  padding-right: 10px;
}
.watchlist-dropdown {
  margin-left: 10px;
}
.search {
  &-logo {
    height: 50px;
  }
  &-data {
    font-size: 1.5rem;
    font-weight: 700;
  }
  &-desc {
    display: flex;
  }
  &-button {
    border-bottom-right-radius: 20px !important;
    border-top-right-radius: 20px !important;
  }
  &-company-name {
    font-size: 2rem;
    font-weight: 600;
    line-height: 1;
  }
  &-sub-data-green {
    background-color: #d6ecdf;
    color: #668371;
    font-size: 1.2rem;
    margin-left: 0.5rem;
    padding: 0 0.2rem;
    border-radius: 3px;
  }
  &-sub-data-red {
    background-color: #ebcccc;
    color: #a95f5f;
    font-size: 1.2rem;
    margin-left: 0.5rem;
    padding: 0 0.2rem;
    border-radius: 3px;
  }
}
.font {
  &-1 {
    font-size: 0.75rem;
  }
}

.index {
  &-green {
    color: #19965b;
  }
  &-red {
    color: #f44336;
  }
  &-pct-change {
    padding-left: 10px;
  }
}

.color {
  &-green {
    color: #19965b;
  }
  &-red {
    color: #f44336;
  }
}
.data {
  &-container {
    width: 90%;
    margin: 1rem auto;
  }
  &-title {
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
  }
  &-value {
    font-size: 0.9rem;
  }
  &-value-metric {
    font-weight: 600;
  }
}
.flex-column {
  // flex: calc(16% - 10px);
  flex: 0 0 calc(25% - 10px);
  flex-basis: calc(25% - 10px);
  max-width: calc(25% - 10px);
  margin: 0 5px;
}
.chart {
  &-content {
    margin: 0 1rem;
    border-radius: 8px;
  }
  &-container {
    width: 90%;
    margin: 0 auto 1rem;
  }
  &-button-group {
    // width: 20vw;
    margin: 0 auto;
  }
  &-row {
    width: 100vw;
  }
  &-full-screen {
    position: fixed;
    width: 100% !important;
    height: 100% !important;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    z-index: 999;
    & .chart-icon {
      top: 50px;
    }
  }
  &-column {
    margin: 0 10px;
    padding: 1rem;
    // box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.2);
    background-color: $primary-bg;
    flex: 0 0 calc(33.33% - 20px) !important;
    flex-basis: calc(33.33% - 20px) !important;
    max-width: calc(33.33% - 20px) !important;
  }
  &-column-dark {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
      rgba(0, 0, 0, 0.22) 0px 15px 12px;
  }
  &-column-light {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  &-numbers {
    font-size: 1.5rem;
    font-weight: 700;
    margin-right: 10px;
  }
  &-numbers-change-green {
    color: #19965b;
  }
  &-numbers-change-red {
    color: #f44336;
  }
  &-sub-title {
    color: #605e5e;
    font-size: 14px;
  }
}
@media screen and (max-width: 1200px) {
  .input-content {
    width: 50%;
  }
  .forum-right {
    display: none !important;
  }
  .chat-left {
    display: none !important;
  }
}
@media screen and (min-width: 1200px) {
  .input-content {
    width: 50%;
  }
  .chat-left {
    width: 25%;
    margin-right: 0px !important;
    display: block !important;
  }
  .chat-list-dropdown{
    display: none !important;
  }

  .forum-left {
    margin-right: 0;
  }
  .forum-right {
    width: 30%;
    display: block !important;
    img {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
  }
  .slider-modal {
    width: 30vw !important;
    float: right;
  }
}
@media screen and (max-width: 991px) {
  .input-content {
    width: 60%;
  }
  .flex-column {
    flex: 0 0 calc(50% - 10px);
    flex-basis: calc(50% - 10px);
    max-width: calc(50% - 10px);
  }
  .chart-column {
    flex: 0 0 calc(50% - 20px) !important;
    flex-basis: calc(50% - 20px) !important;
    max-width: calc(50% - 20px) !important;
  }
}
@media (max-width: 767px) {
  .input-content {
    width: 80%;
  }
  .search {
    &-desc {
      display: block;
    }
    &-divider {
      display: none;
    }
  }
}
@media (min-width: 768px) {
  .search-sort-container {
    display: flex;
    align-items: center;
  }
}
@media (max-width: 559px) {
  .flex-column,
  .chart-column {
    flex: 0 0 100% !important;
    max-width: 99% !important;
  }
  .chart-container,
  .chart-row {
    width: 100%;
  }
  .data-value {
    flex: 1;
  }
}

.margin-5-h {
  margin-left: 5px;
  margin-right: 5px;
}
.loader {
  display: flex;
  justify-content: center;
}

.compare-section {
  .input-content {
    width: 70%;
  }
  .flex-column {
    flex: 0 0 calc(50% - 10px);
    flex-basis: calc(50% - 10px);
    max-width: calc(50% - 10px);
  }
  .chart-column {
    flex: 0 0 calc(50% - 20px) !important;
    flex-basis: calc(50% - 20px) !important;
    max-width: calc(50% - 20px) !important;
  }
  .chart-container,
  .chart-row {
    width: 100%;
  }
  .chart-content {
    margin-right: 5px;
  }
  .star {
    display: none;
  }
}
@media (max-width: 1024px) {
  .compare-section {
    .chart-column {
      flex: 0 0 100% !important;
      max-width: 99% !important;
    }
  }
}
@media (max-width: 991px) {
  .compare-section {
    .flex-column {
      flex: 0 0 100% !important;
      max-width: 99% !important;
    }
  }
}

// Dark Mode
.ag-theme-quartz-dark {
  --ag-foreground-color: #fff !important;
  --ag-background-color: rgb(0, 0, 0) !important;
  --ag-header-foreground-color: #fff !important;
  --ag-header-background-color: #000 !important;
  --ag-odd-row-background-color: rgb(0, 0, 0, 0.03);
  --ag-header-column-resize-handle-color: rgb(126, 46, 132);

  --ag-font-size: 17px;
  --ag-font-family: monospace;
}
// Light Mode
.ag-theme-quartz {
  --ag-foreground-color: rgb(0, 0, 0) !important;
  --ag-background-color: #fff !important;
  --ag-header-foreground-color: rgb(204, 245, 172);
  --ag-header-background-color: rgb(209, 64, 129);
  --ag-odd-row-background-color: rgb(0, 0, 0, 0.03);
  --ag-header-column-resize-handle-color: rgb(126, 46, 132);

  --ag-font-size: 17px;
  --ag-font-family: monospace;
}
.ag-root-wrapper {
  border: none !important;
  padding: 1rem !important;
}

.tabs-container {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  width: auto;
  flex-wrap: nowrap !important;
  scrollbar-width: none;
  padding-top: 10px;
}

.scroll-to-top {
  right: 40px;
  bottom: 20px;
  font-size: 30px;
  position: fixed;
  z-index: 2;
  cursor: pointer;
}
.share-button {
  right: 40px;
  bottom: 70px;
  font-size: 30px;
  position: fixed;
  z-index: 2;
  cursor: pointer;
}
.screener {
  &-title {
    font-weight: 600;
    margin-right: 0.9rem;
  }
}

.align-google {
  width: 80%;
  margin-left: 10%;
}

#typehead-sell {
  transform: translate(0px, 38px) !important;
}

.portfolio-chart-button {
  padding: 0.1rem 0.25rem !important;
}

// .name-edit-input {
//   // width: 100% !important;
// }

@media (min-width: 768px) {
  .name-edit-input {
    width: 50% !important;
  }
}

#typehead-main {
  transform: translate(0px, 38px) !important;
}
#typehead-search {
  transform: translate(0px, 38px) !important;
  font-size: 0.9rem !important; /* Set your desired font size */
}

[id^="typehead-main-transform-"] {
  // transform: translate3d(0px, 38px, 0px) !important;
  // transform: translate(0px, 38px) !important
}
@media (max-width: 400px) {
  .hide-mobile {
    display: none;
  }
}

.scroll-div {
  overflow: scroll;
}

.searchbar-container {
  position: relative;
  display: flex;
  align-items: center;
}

.search-input {
  width: 100%;
  padding-right: 40px; /* Add space to avoid overlap with icon */
}

.search-icon {
  width: 33px;
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: #888;
  font-size: 18px;
}
.forum-left img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
// .chat-left {
//   width: 30%;
// }
.chat-right {
  width: 75%;
}

.forum {
  &-left {
    width: 70%;
  }
  &-container {
    width: 95%;
    margin: 1rem auto;
    .pagination {
      display: inline-flex;
      margin: 0;
      .page-link {
        font-size: 12px;
      }
    }
  }
  &-dark {
    background-color: #282a36;
  }
  &-light {
    background-color: #e9ecf0;
  }
  &-list {
    border-radius: 4px;
    padding: 5px 10px;
    &-container {
      border-radius: 4px;
      padding: 10px;
    }
    &-title {
      font-size: 12px;
      color: #aca9a9;
    }
    &-icon {
      font-size: 6px;
      margin-right: 10px;
    }
    &-index {
      font-size: 13px;
      button {
        font-size: 12px;
        padding: 2px 5px;
        margin-left: 10px;
      }
    }
  }
}
.forum-details {
  .pagination {
    display: inline-flex;
    margin: 0;
    .page-link {
      font-size: 12px;
    }
  }
}

.vote-icon {
  font-size: 20px;
}
.forum-details-index button {
  font-size: 12px;
  padding: 2px 5px;
  margin-right: 10px;
}

.user-profile {
  width: 80px !important;
  height: 80px !important;
}
.forum-list-title-display {
  width: 75% !important;
}

.only-mobile {
  display: none !important;
}
@media (max-width: 600px) {
  .hideon-mobile {
    display: none !important;
  }
  .only-mobile {
    display: block !important;
  }
  .forum-details {
    font-size: 20px !important;
  }
  .forum-details-index button {
    font-size: 14px !important;
  }
  .forum-details-index .btn-group-sm > .btn,
  .btn-sm {
    font-size: 1rem !important;
  }
  .large-button {
    font-size: 1.25rem !important;
  }
  .slider-flex-column {
    flex: 0 0 100% !important;
    flex-basis: 100% !important;
    max-width: 100% !important;
  }
  .slider-modal {
    width: 100% !important;
  }
}
@media (max-width: 500px) {
  .content {
    margin: 5px !important;
  }
}
@media (max-width: 800px) {
  .forum-list-upvotes-display {
    display: none !important;
  }
  .forum-list-title-display {
    width: 100% !important;
  }
}

.image-upload-button {
  top: -18px;
  right: -50px;
  font-size: 23px;
}

.slider-container {
  width: calc(100% - 32px);
  display: flex;
  .logo-container {
    border-radius: 0 !important;
  }
  .logo-column {
    display: flex;
    flex-wrap: wrap;
    img {
      // padding: 5px;
      width: 100%;
    }
  }
  .border-radius-5 {
    border-radius: 5px;
  }
}
.slider-flex-column {
  flex: 0 0 20%;
  flex-basis: 20%;
  max-width: 20%;
  // border-right: 1px solid;
  // margin: 0 5px;
}
.slider-flex-column:nth-last-child(2) {
  border-right: none;
}
.slider-heading {
  text-align: center;
  padding: 5px;
}
.slider-modal {
  float: right;
  .company-name {
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
  }
  .company-data {
    font-size: 1rem;
    font-weight: 600;
  }
}

@media (min-width: 601px) and (max-width: 1199px) {
  .slider-flex-column {
    flex: 0 0 50% !important;
    flex-basis: 50% !important;
    max-width: 50% !important;
  }
  .slider-modal {
    width: 50vw !important;
  }
}

.modal-image {
  max-height: 70px;
}

.modal-graph {
  height: 300px;
}

.font-14 {
  font-size: 14px;
}

.full-height {
  height: calc(100vh - 80px) !important;
}

.message-container {
  height: calc(100vh - 150px) !important;
}

.send-button {
  border-radius: 50px !important;
  position: absolute !important;
  bottom: 5px !important;
  right: 5px !important;
  z-index: 100 !important;
}

.h-20 {
  height: 2rem !important;
}
.text-overflow-ellipsis{
  width:95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.chat-list-item:hover {
  background-color: #2b2b2b;
  border-radius: 5px;
}

.three-dot-menu-toggle::after{
  display: none !important;
}

.rte-editor {
  min-height: 240px; /* Enough space for 10+ lines */
  max-height: 240px; /* Optional: prevents excessive growth */
  overflow-y: auto; /* Enables scrolling if content exceeds height */
  // padding: 10px;
  cursor: text; /* Makes entire area clickable */


}

.rte-editor .DraftEditor-root {
  height: 100%; /* Makes the editor fill the container */
}

.rte-editor .public-DraftEditor-content {
  min-height: 100%; /* Ensures full area is clickable */
}

.rte-editor-comments {
  min-height: 100px; /* Enough space for 10+ lines */
  max-height: 100px; /* Optional: prevents excessive growth */
  overflow-y: auto; /* Enables scrolling if content exceeds height */
  // padding: 10px;
  cursor: text; /* Makes entire area clickable */
}

.rte-editor-comments .DraftEditor-root {
  height: 100%; /* Makes the editor fill the container */
}

.rte-editor-comments .public-DraftEditor-content {
  min-height: 100%; /* Ensures full area is clickable */
}

.rte-dark button:disabled {
  background: #b4b4b4 !important;
}
.rte-dark input {
  color: #000000 !important;
}

.rte-dark input[type="checkbox"]{
  color: #ffffff !important;
}

.rte-dark label span{
  color: #000000 !important;

}
