@import "../../styles/color.scss";

.header {
  height: 3rem;
  width: 100%;
  top: 0;
  position: fixed;
  box-sizing: border-box;
  padding: 0 1rem;
  z-index: 99;
  background-color: $primary-bg;
  box-shadow: 0 8px 25px 0 rgba(10, 10, 10, 0.1);
}
.logo {
  max-height: 52px;
}
.nav {
  &-dropdown {
    font-size: 2rem;
    margin-left: 10px;
    & a::after {
      display: none !important;
    }
  }
  &-link-hidden {
    display: flex;
  }
}
@media (min-width: 800px) {
  .nav-dropdown {
    display: none !important;
  }
}
@media (max-width: 799px) {
  .nav-link-hidden {
    display: none !important;
  }
}

.color-text-dark {
  color: #fff;
}
.color-text-light {
  color: #000;
}

.link-background {
  &-dark {
    background-color: #202428;
    padding-left: 10px;
    padding-right: 10px;
    text-decoration: none !important;
    margin-right: 5px;
    height: 51px;
  }
  &-light {
    background-color: #ebebeb;
    // border-radius: 15px;
    padding-left: 10px;
    padding-right: 10px;
    text-decoration: none !important;
    margin-right: 5px;
    height: 51px;
  }
}

.index {
  &-green {
    color: #19965b;
  }
  &-red {
    color: #f44336;
  }
}

.no-underline {
  text-decoration: none !important;
}
