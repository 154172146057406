body {
  font-family: Inter !important;
}
.sidebar {
  position: absolute;
  top: 3rem;
  height: calc(100vh - 3rem);
  width: 200px;
  padding: 40px 20px;
  font-size: 14px;
  border-radius: 0 !important;
  overflow: auto;
}
.section {
  position: absolute;
  top: 3rem;
  left: 200px;
  height: calc(100vh - 3rem);
  width: calc(100% - 200px);
  overflow: auto;
}
select:focus,
input:focus {
  outline: none;
}
.form-control:focus,
.form-select:focus {
  box-shadow: none !important;
  border-color: #495057 !important;
}
.cursor-pointer {
  cursor: pointer;
}
.visibility-hidden {
  visibility: hidden;
}
@media (min-width: 768px) {
  .compare-section {
    float: left;
    width: 50%;
  }
}
@media (max-width: 799px) {
  .sidebar {
    display: none !important;
  }
  .section {
    left: 0;
    width: 100%;
  }
}

.username-field {
  position: absolute;
  bottom: 0px !important;
}


#typehead-search {
  transform: translate(0px, 38px) !important;
  font-size: 0.9rem !important; /* Set your desired font size */
}